import React from "react";


import ToursHero from "../components/ToursHero";
import ToursAll from "../components/ToursAll";

function ToursPage(props) {
  return (
    <>
      <ToursHero />
      <ToursAll />
      
    </>
  );
}

export default ToursPage;
