import React from "react";
import ContactSection from "../components/ContactSection";
import BookTicketsHero from "../components/BookTicketsHero";
import BookTicketsForm from "../components/BookTicketsForm";


function BookYourTickets(props) {
  return (
    <>
    <BookTicketsHero />
    <BookTicketsForm />
    
    
    </>
  );
}

export default BookYourTickets;
